import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default function SeoContent({ pageSeo = {} }) {
  const { strapiGlobal } = useStaticQuery(query);
  const { Seo, siteName, favicon } = strapiGlobal;

  // Merge default and page-specific SEO values
  const fullSeo = { ...Seo, ...pageSeo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.localFile.childImageSharp.fluid.src;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.localFile.childImageSharp.fluid.src,
        },
      ]}
      meta={metaTags}
      htmlAttributes={{
        lang: 'en',
      }}
    />
  );
}

SeoContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

SeoContent.defaultProps = {
  title: null,
  description: null,
  image: null,
};

const query = graphql`
  query {
    strapiGlobal {
      favicon {
        localFile {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
      siteName
      Seo {
        shareImage {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        metaDescription
        metaTitle
      }
    }
  }
`;
