import React from "react";

export default function ContainerHeader(props) {
  if (!props.text) props.text = "";

  return (
    <div className="m-auto md:w-1/3 w-full pb-4">
      <hr className="bg-gray-700" />
      <h1 className="m-auto font-bold text-center md:text-xl py-4 uppercase text-lg">
        {props.text}
      </h1>
      <hr className="bg-gray-700" />
    </div>
  );
}
