import React from "react";
import ReactMarkdown from "react-markdown";

export default function ContainerContent(props) {
  if (!props.text) props.text = "";
  return (
    <div className="py-4">
      <ReactMarkdown
        className="pb-2 mx-auto prose-lg prose md:text-left text-center max-w-none"
        linkTarget={"_blank"}
        source={props.text}
      />
    </div>
  );
}
